/**
 * Combined by jsDelivr.
 * Original files:
 * - /gh/highlightjs/cdn-release@11.9.0/build/languages/bash.min.js
 * - /gh/highlightjs/cdn-release@11.9.0/build/languages/csharp.min.js
 * - /gh/highlightjs/cdn-release@11.9.0/build/languages/css.min.js
 * - /gh/highlightjs/cdn-release@11.9.0/build/languages/javascript.min.js
 * - /gh/highlightjs/cdn-release@11.9.0/build/languages/json.min.js
 * - /gh/highlightjs/cdn-release@11.9.0/build/languages/markdown.min.js
 * - /gh/highlightjs/cdn-release@11.9.0/build/languages/plaintext.min.js
 * - /gh/highlightjs/cdn-release@11.9.0/build/languages/powershell.min.js
 * - /gh/highlightjs/cdn-release@11.9.0/build/languages/python.min.js
 * - /gh/highlightjs/cdn-release@11.9.0/build/languages/scss.min.js
 * - /gh/highlightjs/cdn-release@11.9.0/build/languages/sql.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
